import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import { dataAssetClient, useDataTypeTreeManager } from "../../../../api/dataAssetApi";
import { useCallback, useEffect, useMemo, useState } from "react";
import TextBody2 from "../../../../../components/TextBody2/TextBody2";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import DocView from "../../../../../components/DocView/DocView";
import DocMetaView from "../../../../../components/DocMetaView/DocMetaView";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { CheckBoxOutlined } from "@mui/icons-material";
import { isAxiosErrorWithCode } from "../../../../api/axios/axiosErrorHandler";
import { useTranslation } from "react-i18next";

export const CleanEmptyDataAsset = () => {
  const { data: personGroups, isLoading, dataById, mutate } = useDataTypeTreeManager(true);

  const [deletedAssetIds, setDeletedAssetIds] = useState<string[]>([]);
  const deletedAssetIdsSet = useMemo(() => new Set(deletedAssetIds), [deletedAssetIds]);

  const { t } = useTranslation();
  const translateDataAsset = useCallback(key => t(`lists_data_types_categories_person_groups:${key}`, key), [t]);

  const [emptyDataAssets, setEmptyDataAssets] = useState<(EmptyPersonGroup | EmptyDataCategory)[]>([]);
  useEffect(() => {
    if (!personGroups) {
      setEmptyDataAssets([]);
      return;
    }

    const emptyDataAssets: (EmptyPersonGroup | EmptyDataCategory)[] = [];
    for (const pg of personGroups) {
      const allDataCategoriesEmpty = pg.dataCategories.every(dc => dc.dataTypes.length === 0);
      const pgName = translateDataAsset(pg.personGroupKey);
      if (allDataCategoriesEmpty) {
        emptyDataAssets.push({
          id: pg.id || "",
          name: pgName,
          type: "person-group"
        });
      }

      for (const dc of pg.dataCategories) {
        if (allDataCategoriesEmpty || dc.dataTypes.length === 0) {
          const dcName = translateDataAsset(dc.dataCategoryKey);
          emptyDataAssets.push({
            id: dc.id || "",
            name: dcName,
            type: "data-category",
            personGroupId: pg.id || "",
            personGroupName: pgName
          });
        }
      }
    }

    setEmptyDataAssets(emptyDataAssets);
  }, [personGroups, translateDataAsset]);

  const [currentlyBeingDeleted, setCurrentlyBeingDeleted] = useState<string>("");
  const deleteEmptyPersonGroup = useCallback(
    async personGroupId => {
      const personGroupToDelete = dataById?.personGroups[personGroupId];
      if (!personGroupToDelete) {
        throw new Error(`Unable to find person group to delete: ${personGroupId}`);
      }

      const dataTypeCount = personGroupToDelete.dataCategories.flatMap(it => it.dataTypes);
      if (dataTypeCount.length) {
        throw new Error(`Person group ${personGroupId} has data types and cannot be deleted`);
      }

      try {
        setCurrentlyBeingDeleted(personGroupId);
        await dataAssetClient.deleteDataAsset(personGroupId, "true");
      } catch (e: unknown) {
        if (!isAxiosErrorWithCode(e, 404)) {
          throw e;
        }
      }
      setDeletedAssetIds(prev => [...prev, personGroupId, ...personGroupToDelete.dataCategories.map(it => it.id)]);
    },
    [dataById?.personGroups]
  );

  const deleteEmptyDataCategory = useCallback(
    async dataCategoryId => {
      const dataCategoryToDelete = dataById?.dataCategories[dataCategoryId];
      if (!dataCategoryToDelete) {
        throw new Error(`Unable to find data category to delete: ${dataCategoryId}`);
      }

      const dataTypeCount = dataCategoryToDelete.dataTypes.length;
      if (dataTypeCount) {
        throw new Error(`Data category ${dataCategoryId} has data types and cannot be deleted`);
      }

      try {
        setCurrentlyBeingDeleted(dataCategoryId);
        await dataAssetClient.deleteDataAsset(dataCategoryId, "true");
      } catch (e: unknown) {
        if (!isAxiosErrorWithCode(e, 404)) {
          throw e;
        }
      }

      setDeletedAssetIds(prev => [...prev, dataCategoryId]);
    },
    [dataById?.dataCategories]
  );

  const [isDeletingAll, setIsDeletingAll] = useState(false);
  const deleteEmptyDataAssets = useCallback(async () => {
    setIsDeletingAll(true);
    const localDeletedAssetIds = new Set([...deletedAssetIdsSet]);

    try {
      for (const dataAsset of emptyDataAssets) {
        if (localDeletedAssetIds.has(dataAsset.id)) {
          continue;
        }

        if (dataAsset.type === "person-group") {
          await deleteEmptyPersonGroup(dataAsset.id);
          localDeletedAssetIds.add(dataAsset.id);
        } else if (dataAsset.type === "data-category") {
          const parentAlreadyDeleted = localDeletedAssetIds.has(dataAsset.personGroupId);
          if (!parentAlreadyDeleted) {
            await deleteEmptyDataCategory(dataAsset.id);
          }
        }
      }
    } catch (error: unknown) {
      setIsDeletingAll(false);
      throw error;
    }
    setIsDeletingAll(false);

    mutate();
  }, [deletedAssetIdsSet, mutate, emptyDataAssets, deleteEmptyPersonGroup, deleteEmptyDataCategory]);

  if (isLoading) {
    return (
      <Box display="flex" height={300} justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box mb={2}>
        <TextBody2 text="This page will list all person groups and data categories that does not have any data types" />
      </Box>
      {emptyDataAssets.length ? (
        <Box width={400}>
          <List>
            {emptyDataAssets.map(dataAsset => {
              const isPersonGroup = dataAsset.type === "person-group";
              const isBeingDeleted =
                (isPersonGroup
                  ? currentlyBeingDeleted === dataAsset.id
                  : currentlyBeingDeleted === dataAsset.personGroupId || currentlyBeingDeleted === dataAsset.id) &&
                !deletedAssetIdsSet.has(dataAsset.id);

              return (
                <Collapse key={dataAsset.id} in={!deletedAssetIdsSet.has(dataAsset.id)}>
                  <ListItem
                    secondaryAction={
                      isBeingDeleted ? (
                        <CircularProgress size={16} />
                      ) : (
                        <IconButton
                          color="error" /* eslint-disable-next-line react/jsx-no-bind */
                          onClick={
                            isPersonGroup
                              ? () => deleteEmptyPersonGroup(dataAsset.id)
                              : () => deleteEmptyDataCategory(dataAsset.id)
                          }
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )
                    }
                  >
                    <ListItemIcon>
                      {isPersonGroup ? (
                        <Person2OutlinedIcon color="primary" />
                      ) : (
                        <CategoryOutlinedIcon color="success" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      id={dataAsset.id}
                      primary={dataAsset.name}
                      secondary={isPersonGroup ? undefined : dataAsset.personGroupName}
                    />
                  </ListItem>
                </Collapse>
              );
            })}
          </List>

          <Box mt={2}>
            <Button
              color="error"
              onClick={deleteEmptyDataAssets}
              variant="contained"
              fullWidth
              disabled={isDeletingAll}
            >
              <Box display="flex" gap={1} alignItems="center">
                Delete All Empty Data Assets
                {isDeletingAll ? <CircularProgress size={14} /> : <></>}
              </Box>
            </Button>
          </Box>
        </Box>
      ) : (
        <Box display="flex" gap={1}>
          <CheckBoxOutlined color="success" />
          <TextBody2 text="Currently no person groups and data categories without any data types" />
        </Box>
      )}
    </Box>
  );
};

export const CleanEmptyDataAssetPage = () => {
  return (
    <DocMetaView>
      <DocView header="Data Asset Cleaner">
        <CleanEmptyDataAsset />
      </DocView>
    </DocMetaView>
  );
};

export interface EmptyPersonGroup {
  readonly id: string;
  readonly name: string;
  readonly type: "person-group";
}

export interface EmptyDataCategory {
  readonly id: string;
  readonly name: string;
  readonly type: "data-category";
  readonly personGroupId: string;
  readonly personGroupName: string;
}
