import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import colors from "theme/palette/colors";
import stopEvent from "tool/stopEvent";

export interface StepperGroupProps {
  readonly id: string;
  readonly name: string;
}

export interface StepperItemProps {
  readonly id?: string;
  readonly label: string;
  readonly labelElement?: React.ReactNode;
  readonly selected?: boolean;
  readonly disabled?: boolean;
  readonly path?: string;
  readonly icon?: React.ReactNode;
  readonly href?: string;
  readonly tooltip?: string;
  readonly number?: number;
  readonly disable?: boolean;
  readonly groupId?: string;
}

const scrollVal = 140;
const minOffset = 10;
const sx = {
  root: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    marginBottom: "16px"
  },
  steps: {
    padding: "32px 0 0 0",
    flex: 1,
    display: "flex",
    overflowY: "hidden",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
      display: "none"
    }
  },
  rightBorder: {
    borderRight: `1px solid ${colors.divider}`
  },
  leftBorder: {
    borderLeft: `1px solid ${colors.divider}`
  },
  nav: {
    width: "30px",
    height: "30px",
    background: colors.indigo.indigo500,
    borderRadius: "50%",
    color: colors.white,
    position: "absolute",
    top: "29px",
    textAlign: "center",
    lineHeight: "46px",
    cursor: "pointer",
    zIndex: 301
  },
  navLeft: { left: "-15px" },
  navRight: { right: "-15px" },
  step: {
    flex: 1,
    minWidth: "120px",
    cursor: "pointer",
    position: "relative"
  },
  stepDisabled: {
    cursor: "default",
    opacity: 0.5
  },
  number: {
    width: "24px",
    height: "24px",
    background: colors.grey.grey400,
    borderRadius: "50%",
    color: colors.white,
    textAlign: "center",
    fontSize: "12px",
    margin: "0 auto",
    lineHeight: "26px"
  },
  stepLeftLine: {
    top: "12px",
    left: "calc(-50% + 20px)",
    right: "calc(50% + 20px)",
    position: "absolute",
    height: "1px",
    background: colors.divider,
    zIndex: 299
  },
  stepRightLine: {
    top: "12px",
    left: "calc(50% + 20px)",
    right: "calc(-50% + 20px)",
    position: "absolute",
    height: "1px",
    background: colors.divider,
    zIndex: 299
  },
  selected: {
    background: colors.indigo.indigo500
  },
  stepContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  label: {
    marginTop: "16px",
    cursor: "pointer",
    textAlign: "center",
    padding: "0 8px"
  },
  group: {
    flex: 1,
    zIndex: 301
  },
  groupStepsWrapper: {
    margin: "auto",
    cursor: "pointer",
    borderLeft: `8px solid ${colors.white}`,
    borderRight: `8px solid ${colors.white}`
  },
  groupSteps: {
    background: colors.grey.grey300,
    display: "flex",
    borderRadius: "12px"
  },
  groupStepsIconsExpanded: {
    display: "flex",
    background: colors.indigo.indigo100,
    cursor: "pointer",
    padding: "2px"
  },
  groupStepsIconExpanded: {
    width: "24px",
    height: "24px",
    border: `2px solid ${colors.indigo.indigo100}`,
    background: colors.white,
    color: colors.indigo.indigo600,
    textAlign: "center",
    borderRadius: "50%",
    margin: "0 auto",
    boxSizing: "content-box",
    lineHeight: "26px"
  },
  expandedStepLeftLine: {
    top: "12px",
    left: "calc(-50% + 20px)",
    right: "calc(50% + 20px)",
    position: "absolute",
    height: "1px",
    background: colors.white
  },
  expandedStepLabel: {
    marginTop: "12px",
    cursor: "pointer",
    textAlign: "center",
    padding: "0 8px"
  },
  groupStepsIconSelectedExpanded: {
    color: colors.white,
    background: colors.indigo.indigo500
  },
  groupStepsIconExpandedLeft: {
    background: `linear-gradient(90deg, white 50%, ${colors.indigo.indigo100} 50%)`
  },
  groupStepsIconExpandedRight: {
    background: `linear-gradient(90deg, ${colors.indigo.indigo100} 50%, white 50%)`
  },
  groupNameExpanded: {
    position: "absolute",
    left: "0px",
    top: "-32px",
    textAlign: "center",
    color: colors.grey.grey500
  },
  groupStepsIconExpandedWrapper: {
    background: colors.indigo.indigo100,
    cursor: "pointer",
    position: "relative"
  },
  groupStepsLabelsExpanded: {
    display: "flex"
  },
  groupStepsLabelExpanded: {
    display: "flex"
  },
  groupItem: {
    color: colors.grey.grey800,
    width: "24px",
    height: "24px",
    border: `2px solid ${colors.grey.grey300}`,
    background: colors.white,
    borderRadius: "50%",
    textAlign: "center",
    fontSize: "12px",
    lineHeight: "22px"
  }
};

interface ExpandedStepProps {
  readonly step: StepperItemProps;
  readonly left: boolean;
  readonly right: boolean;
  readonly groupName: string;
  readonly stepsCount: number;
  readonly needLeftLine?: boolean;
  readonly onStepClick: (step: StepperItemProps) => void;
  readonly onToggle: () => void;
}
const ExpandedStep = ({
  step,
  left,
  right,
  groupName,
  stepsCount,
  needLeftLine,
  onStepClick,
  onToggle
}: ExpandedStepProps) => {
  const onClickCallback = useCallback(
    event => {
      stopEvent(event);
      onStepClick(step);
    },
    [onStepClick, step]
  );

  const onToggleCallback = useCallback(
    event => {
      stopEvent(event);
      onToggle();
    },
    [onToggle]
  );

  return (
    <Box
      key={step.id}
      sx={{ flex: 1, minWidth: "120px", cursor: "pointer", position: "relative" }}
      onClick={onClickCallback}
    >
      {left && (
        <Box sx={{ ...sx.groupNameExpanded, width: `${stepsCount * 120}px` }}>
          <Typography variant="caption" sx={{ color: colors.grey.grey500 }}>
            {groupName}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          ...sx.groupStepsIconExpandedWrapper,
          ...(left ? sx.groupStepsIconExpandedLeft : {}),
          ...(right ? sx.groupStepsIconExpandedRight : {})
        }}
        onClick={onToggleCallback}
      >
        {needLeftLine && <Box sx={sx.expandedStepLeftLine} />}
        <Box onClick={onClickCallback}>
          <Typography
            sx={{ ...sx.groupStepsIconExpanded, ...(step.selected ? sx.groupStepsIconSelectedExpanded : {}) }}
            variant="caption"
          >
            {step.number}
          </Typography>
        </Box>
      </Box>
      <Typography variant="caption" key={step.id} sx={sx.expandedStepLabel}>
        {step.label}
      </Typography>
    </Box>
  );
};

interface CollapsedStepProps {
  readonly step: StepperItemProps;
  readonly index: number;
  readonly onStepClick: (step: StepperItemProps) => void;
}
const CollapsedStep = ({ step, index, onStepClick }: CollapsedStepProps) => {
  const onClickCallback = useCallback(
    event => {
      stopEvent(event);
      onStepClick(step);
    },
    [onStepClick, step]
  );

  return (
    <Tooltip key={step.id} title={step.label}>
      <Box
        sx={{ ...sx.groupItem, marginLeft: index === 0 ? "0px" : "-6px", zIndex: 100 - index }}
        onClick={onClickCallback}
      >
        {step.number}
      </Box>
    </Tooltip>
  );
};

interface GroupProps {
  readonly group: StepperGroupProps;
  readonly steps: StepperItemProps[];
  readonly onStepClick: (step: StepperItemProps) => void;
  readonly onCalck: () => void;
  readonly onExpand: (length: number) => void;
}
const Group = ({ group, steps, onStepClick, onCalck, onExpand }: GroupProps) => {
  const [expand, setExpand] = useState<boolean>(false);

  const toggle = useCallback(() => {
    // if expand - select first step as active
    if (!expand) {
      onStepClick(steps[0]);
      onExpand(steps.length);
    }

    if (steps.some(({ selected }) => selected)) {
      setExpand(true);
    } else {
      setExpand(current => !current);
    }

    onCalck();
  }, [expand, onCalck, onExpand, onStepClick, steps]);

  const onCollapsedStepClick = useCallback(
    (step: StepperItemProps) => {
      if (!expand) {
        onExpand(steps.length);
      }
      onStepClick(step);
    },
    [expand, onExpand, onStepClick, steps.length]
  );

  const onCollapsedLabelClick = useCallback(() => {
    onExpand(steps.length);
    onStepClick(steps[0]);
  }, [onExpand, onStepClick, steps]);

  useEffect(() => {
    setExpand(steps.some(({ selected }) => selected));
  }, [steps]);

  if (!expand) {
    const width = `${steps.length * 22}px`;
    return (
      <Box sx={sx.group}>
        <Box sx={{ ...sx.groupStepsWrapper, width }} onClick={toggle}>
          <Box sx={sx.groupSteps}>
            {steps.map((step, index) => (
              <CollapsedStep key={step.id} index={index} step={step} onStepClick={onCollapsedStepClick} />
            ))}
          </Box>
        </Box>
        <Typography variant="caption" sx={sx.label} onClick={onCollapsedLabelClick}>
          {group.name}
        </Typography>
      </Box>
    );
  } else {
    return (
      <>
        {steps.map((step, index) => (
          <ExpandedStep
            key={step.id}
            step={step}
            left={index === 0}
            right={index === steps.length - 1}
            groupName={group.name}
            stepsCount={steps.length}
            needLeftLine={index !== 0}
            onStepClick={onStepClick}
            onToggle={toggle}
          />
        ))}
      </>
    );
  }
};

interface StepProps {
  readonly step: StepperItemProps;
  readonly index?: number;
  readonly needLeftLine?: boolean;
  readonly needRightLine?: boolean;
  readonly onClick: (step: StepperItemProps) => void;
}
const Step = ({ step, index, needLeftLine, needRightLine, onClick }: StepProps) => {
  const onClickCallback = useCallback(() => {
    if (step.disabled) {
      return;
    }
    if (step.href) {
      window.open(step.href, "_blank");
      return;
    }
    onClick(step);
  }, [onClick, step]);
  return (
    <Box sx={{ ...sx.step, ...(step.disabled ? sx.stepDisabled : {}) }} key={step.id} onClick={onClickCallback}>
      {needLeftLine && <Box sx={sx.stepLeftLine} />}
      <Box sx={{ ...sx.number, ...(step.selected ? sx.selected : {}) }}>
        {step.icon && React.isValidElement(step.icon) ? step.icon : (step.number ?? (index ?? 0) + 1)}
      </Box>
      <Box
        sx={{
          ...(step.disabled ? sx.stepDisabled : {})
        }}
      >
        <Box sx={sx.stepContainer}>
          <Tooltip title={step.tooltip}>
            <Typography variant="caption" sx={sx.label}>
              {step.label}
            </Typography>
          </Tooltip>
          {step.labelElement ?? null}
        </Box>
      </Box>

      {needRightLine && <Box sx={sx.stepRightLine} />}
    </Box>
  );
};

interface StepperProps {
  readonly steps: StepperItemProps[];
  readonly groups?: StepperGroupProps[];
  readonly onClick: (step: StepperItemProps) => void;
}

const Stepper = ({ steps, groups, onClick }: StepperProps) => {
  const [selectedStep, setSelectedStep] = useState<StepperItemProps | null>(null);
  const [navState, setNavState] = useState<{ readonly needLeftNav?: boolean; readonly needRightNav?: boolean }>({
    needLeftNav: false,
    needRightNav: false
  });
  const ref = useRef<HTMLElement>(null);

  const calc = useCallback(() => {
    if (ref.current) {
      const { scrollLeft, scrollWidth, offsetWidth } = ref.current;
      setNavState({
        needLeftNav: scrollLeft > minOffset,
        needRightNav: offsetWidth + scrollLeft - scrollWidth < -minOffset
      });
    }
  }, []);
  window.addEventListener("resize", calc);

  const weelScroll = useCallback((event: WheelEvent) => {
    stopEvent(event);
    const container = ref.current;
    container?.scrollTo({
      left: container.scrollLeft + event.deltaY
    });
  }, []);

  useEffect(() => {
    setSelectedStep(steps.find(({ selected }) => selected) || null);
  }, [steps]);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("wheel", weelScroll);
    }
  }, [ref, weelScroll]);

  useEffect(() => {
    if (ref.current && selectedStep) {
      const index = selectedStep && steps.map(({ id }) => id).indexOf(selectedStep.id);
      const { scrollLeft } = ref.current;
      if (index && scrollLeft === 0) {
        ref.current.scrollTo({
          left: index * scrollVal,
          behavior: "smooth"
        });
      }
    }
  }, [calc, selectedStep, steps]);

  const nextCallback = useCallback(() => {
    if (ref.current) {
      const { scrollLeft, scrollWidth, clientWidth } = ref.current;
      const nextScrollLeft = scrollLeft + scrollVal;
      const restRight = scrollWidth - clientWidth - scrollLeft - scrollVal;
      ref.current.scrollTo({
        left: restRight < scrollVal ? nextScrollLeft + restRight : nextScrollLeft,
        behavior: "smooth"
      });
    }
  }, []);

  const prevCallback = useCallback(() => {
    if (ref.current) {
      const { scrollLeft } = ref.current;
      const nextScrollLeft = scrollLeft - scrollVal;
      ref.current.scrollTo({
        left: nextScrollLeft < scrollVal ? 0 : nextScrollLeft,
        behavior: "smooth"
      });
    }
  }, []);

  const onExpandGroupCallback = useCallback((length: number) => {
    if (ref.current) {
      const container = ref.current;
      const nextScrollLeft = container.scrollLeft + scrollVal * length;
      setTimeout(() => {
        ref.current?.scrollTo({
          left: nextScrollLeft,
          behavior: "smooth"
        });
      }, 100);
    }
  }, []);

  const onClickCallback = useCallback(
    (step: StepperItemProps) => {
      setTimeout(() => {
        const index = steps.map(({ id }) => id).indexOf(step.id);
        if (ref.current) {
          const { scrollLeft, scrollWidth, clientWidth } = ref.current;
          const nextScrollLeft = scrollLeft + scrollVal;
          const restRight = scrollWidth - clientWidth - scrollLeft - scrollVal;

          // right side
          if ((index === steps.length - 1 || index === steps.length - 2) && restRight < minOffset) {
            ref.current.scrollTo({
              left: nextScrollLeft + restRight,
              behavior: "smooth"
            });
          }

          // left side
          if ((index === 0 || index == 1) && scrollLeft < scrollVal) {
            ref.current.scrollTo({
              left: 0,
              behavior: "smooth"
            });
          }
        }

        calc();
      }, 100);

      onClick(step);
    },
    [calc, onClick, steps]
  );

  const prevEl = useMemo(() => {
    if (!navState.needLeftNav) {
      return <></>;
    }
    return (
      <Box sx={{ ...sx.nav, ...sx.navLeft }} onClick={prevCallback}>
        <ChevronLeft />
      </Box>
    );
  }, [navState.needLeftNav, prevCallback]);

  const nextEl = useMemo(() => {
    if (!navState.needRightNav) {
      return <></>;
    }
    return (
      <Box sx={{ ...sx.nav, ...sx.navRight }} onClick={nextCallback}>
        <ChevronRight />
      </Box>
    );
  }, [navState.needRightNav, nextCallback]);

  const stepsEl = useMemo(() => {
    const existStepIds: string[] = [];
    return (
      <Box sx={sx.steps} ref={ref} onScroll={calc}>
        {steps.map((step, index) => {
          if (step.groupId) {
            if (existStepIds.includes(step.groupId)) {
              return <></>;
            } else {
              const _group = groups?.find(({ id }) => id === step.groupId);
              if (_group) {
                existStepIds.push(_group.id);
                const _steps = steps.filter(({ groupId }) => groupId === _group.id);
                return (
                  <Group
                    key={step.groupId}
                    group={_group}
                    steps={_steps}
                    onStepClick={onClickCallback}
                    onCalck={calc}
                    onExpand={onExpandGroupCallback}
                  />
                );
              }
              return (
                <Step
                  key={step.id || step.path}
                  step={step}
                  onClick={onClickCallback}
                  needLeftLine={index !== 0}
                  index={index}
                />
              );
            }
          }
          return (
            <Step
              key={step.id || step.path}
              step={step}
              onClick={onClickCallback}
              needLeftLine={index !== 0}
              needRightLine={Boolean(steps[index + 1] && steps[index + 1].groupId)}
              index={index}
            />
          );
        })}
      </Box>
    );
  }, [calc, groups, onClickCallback, onExpandGroupCallback, steps]);
  return (
    <Box
      sx={{
        ...sx.root,
        ...(navState.needRightNav ? sx.rightBorder : {}),
        ...(navState.needLeftNav ? sx.leftBorder : {})
      }}
    >
      {prevEl}
      {stepsEl}
      {nextEl}
    </Box>
  );
};

export default Stepper;
