import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AssetShortDTO, AssetUpdateDTO } from "../types/AssetTypes";
import { createAsset, getAsset, getAssets } from "../handler/assetHandler";
import AssetDetails from "./AssetDetails";
import { LinkText } from "app/router/router-filters";
import MultiAutocomplete from "components/MultiAutocomplete/MultiAutocomplete";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";
import { useAuthentication } from "../../../handlers/authentication/authentication-context";
import Dialog from "@mui/material/Dialog";
import { Box, Button, Card, CardContent, Chip } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";

interface AssetTagProps {
  readonly assetId: string;
  readonly assetTitle: string;

  readonly onDelete: (id: string) => void;
  readonly onClick: (id: string) => void;
}
const AssetTag = ({ assetId, assetTitle, onDelete, onClick }: AssetTagProps) => {
  const onDeleteCallback = useCallback(() => {
    onDelete(assetId);
  }, [assetId, onDelete]);
  const onClickCallback = useCallback(() => {
    onClick(assetId);
  }, [assetId, onClick]);
  return <Chip label={assetTitle} onDelete={onDeleteCallback} onClick={onClickCallback} />;
};

/* ASSET MILTISELECT CHIP */
type AssetMultiselectProps = {
  readonly disabled?: boolean;
  readonly orgUnitIds?: string[] | undefined;
  readonly selectedAssetIds: string[];
  readonly onAddNew?: (val: string) => void;
  readonly onBlur?: () => void;
  readonly onChange?: (val: string[]) => void;
  readonly onFocus?: () => void;
};

const AssetsMultiSelect = ({
  disabled,
  onAddNew,
  onBlur,
  onChange,
  onFocus,
  orgUnitIds,
  selectedAssetIds
}: AssetMultiselectProps) => {
  const { t } = useTranslation("asset_details");
  const { loadSeenItemsOfUserHook } = useUserAndTenantData();

  const [currentSelectedIds, setCurrentSelectedIds] = useState<string[]>([]);
  const [availableAssets, setAvailableAssets] = useState<AssetShortDTO[]>([]);
  const [availableAssetsNameById, setAvailableAssetsNameById] = useState<{ [key: string]: string }>();
  const [assetData, setAssetData] = useState<AssetUpdateDTO | null>(null);

  useEffect(() => {
    setCurrentSelectedIds(selectedAssetIds);
  }, [selectedAssetIds]);

  const fetchData = useCallback(async () => {
    const [aAssets, sAssets] = await Promise.all([
      await getAssets({ anyOrgUnitIds: orgUnitIds }),
      await getAssets({ assetIds: selectedAssetIds })
    ]);

    const availableAssets: AssetShortDTO[] = [];
    const assetNameById: { [key: string]: string } = {};
    for (const asset of [...(aAssets?.items || []), ...(sAssets?.items || [])]) {
      if (!assetNameById[asset.id]) {
        assetNameById[asset.id] = asset.name;
        availableAssets.push(asset);
      }
    }
    setAvailableAssets(availableAssets);
    setAvailableAssetsNameById(assetNameById);
  }, [orgUnitIds, selectedAssetIds]);

  useEffect(() => {
    const fetch = async () => {
      fetchData();
    };
    fetch();
  }, [fetchData]);

  const showAssetDetails = useCallback(async id => {
    const data = await getAsset(id);
    if (data) {
      setAssetData(data as any);
    } else {
      // do nothing
    }
  }, []);

  /* ASSETS EDIT DIALOG */
  const closeEditAsset = useCallback(() => {
    setAssetData(null);
    fetchData();
  }, [fetchData]);

  const editAssetDialogEl = (
    <Dialog open={Boolean(assetData)} onClose={closeEditAsset} aria-labelledby="form-dialog-title" maxWidth={"xl"}>
      <Card style={{ overflow: "auto" }}>
        <CardContent>
          <Box mx={4} mt={2} mb={4}>
            <LinkText pathname={`/asset-management/${assetData?.id}/general`}>{assetData?.name}</LinkText>
          </Box>
          <Box mx={4}>
            <AssetDetails id={assetData?.id || ""} />
          </Box>
        </CardContent>
        <DialogActions>
          <Box p={2}>
            <Button variant="outlined" color="primary" onClick={closeEditAsset}>
              {t("common:close")}
            </Button>
          </Box>
        </DialogActions>
      </Card>
    </Dialog>
  );

  /* ASSETS AUTOCOMPLETE */
  const assetOptions: string[] = useMemo(() => availableAssets.flatMap(a => a.id), [availableAssets]);

  const onChangeAssetAutocomplete = useCallback(
    newValues => {
      onChange?.(newValues);
      setCurrentSelectedIds(newValues);
    },
    [onChange]
  );

  const getAvailableOptionLabel = useCallback(
    id => {
      return availableAssetsNameById?.[id] || id;
    },
    [availableAssetsNameById]
  );

  const newOptionEntryAlreadyExists = useCallback(
    newAssetName =>
      availableAssets.some(({ name }) => newAssetName === name) || selectedAssetIds.includes(newAssetName),
    [availableAssets, selectedAssetIds]
  );

  const updateOptions = useCallback(
    async options => {
      const newOptionName = options.find((newName: string) => !assetOptions.includes(newName));
      if (newOptionName) {
        onAddNew?.(newOptionName);
        const newId: string = await createAsset({ name: newOptionName });
        await loadSeenItemsOfUserHook();

        if (newId) {
          const newValues: string[] = [...new Set([...currentSelectedIds, newId])];
          onChange?.(newValues);
          setCurrentSelectedIds(newValues);
          showAssetDetails(newId);
        }
      }
    },
    [assetOptions, currentSelectedIds, loadSeenItemsOfUserHook, onAddNew, onChange, showAssetDetails]
  );

  const onDeleteAssetTag = useCallback(
    (id: string) => {
      const newValues = selectedAssetIds.filter(_id => _id !== id);
      onChange?.(newValues);
      setCurrentSelectedIds(newValues);
    },
    [onChange, selectedAssetIds]
  );
  const renderTags = useCallback(
    (ids: string[]) => {
      return ids.map(id => {
        return (
          <AssetTag
            key={id}
            assetTitle={getAvailableOptionLabel(id)}
            assetId={id}
            onDelete={onDeleteAssetTag}
            onClick={showAssetDetails}
          />
        );
      });
    },
    [getAvailableOptionLabel, onDeleteAssetTag, showAssetDetails]
  );

  const { auth } = useAuthentication();
  const userHasAssetWritePermission = auth?.permissions?.some(
    permission => permission === "asset_write_org" || permission === "asset_write_all"
  );

  const autocompleteEl = (
    <MultiAutocomplete<string, true, false, false>
      addText={userHasAssetWritePermission ? t("add_text") : undefined}
      disableClearable={false}
      disabled={disabled}
      getOptionLabel={getAvailableOptionLabel}
      hasMultiSelect={true}
      id={"assignedAssets"}
      label={t("asset_details:search")}
      newOptionEntryAlreadyExists={newOptionEntryAlreadyExists}
      onBlur={onBlur}
      onFocus={onFocus}
      options={assetOptions}
      placeholder={t("search")}
      selected={currentSelectedIds}
      updateOptions={updateOptions}
      updateSelected={onChangeAssetAutocomplete}
      renderTags={renderTags}
    />
  );

  return (
    <>
      {autocompleteEl}
      {editAssetDialogEl}
    </>
  );
};

export default AssetsMultiSelect;
