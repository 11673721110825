import { COLLECTIONS } from "app/collections";
import DocMetaView from "components/DocMetaView/DocMetaView";
import DocView from "components/DocView/DocView";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDataSubjectRequest } from "app/contexts/dsr-context";
import React, { useCallback, useMemo, useEffect } from "react";
import Stepper, { StepperItemProps } from "components/Stepper/Stepper";
import { useUserDepartments } from "app/contexts/department-context";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import AddIcon from "@mui/icons-material/Add";
import { LazySvgIcon } from "components/LazySvgIcon/LazySvgIcon";
import { useErrorSnackbar } from "hook/errorSnackbar";
import { useCurrentTenantSettings } from "hook/useTenantSettings";
import { OpenInNew } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { Box } from "@material-ui/core";
import RequestGeneralPage from "./RequestGeneralPage";
import RequestDataPage from "./RequestDataPage";
import MetaView from "components/MetaView/MetaView";
import { useDocumentName } from "app/contexts/document-name-context";

const requestPaths = ["general", "data"];
type REQUEST_STATUSES = "OPEN" | "DONE" | "REVIEW" | "INPROGRESS";
const statusIconMap: Record<REQUEST_STATUSES, React.ReactNode> = {
  OPEN: <LazySvgIcon name="Not_Started" />,
  INPROGRESS: <LazySvgIcon name="In_Progress" />,
  REVIEW: <LazySvgIcon name="In_Review" />,
  DONE: <LazySvgIcon name="Completed" />
};

const RequestPage = () => {
  const { t } = useTranslation("data_subject_requests_overview_general_page");
  const id = useParams()?.id || "";
  const page = useParams()?.page || "";
  const navigate = useNavigate();
  const { isPartOfUserDepartments } = useUserDepartments();
  const { setDocumentName } = useDocumentName();
  const { auth } = useAuthentication();
  const { catchAsSnackbar } = useErrorSnackbar();
  const { initialized, dataSubjectRequest, updateBasicDataHook, renameHook } = useDataSubjectRequest();

  const onHeaderChange = useCallback(
    title => {
      renameHook(title).catch(catchAsSnackbar("failed to rename"));
    },
    [catchAsSnackbar, renameHook]
  );

  const onStatusChange = useCallback(
    async (status: string) => {
      updateBasicDataHook({ status: status as REQUEST_STATUSES }).catch(catchAsSnackbar("failed to update status"));
    },
    [updateBasicDataHook, catchAsSnackbar]
  );

  useEffect(() => {
    setDocumentName(dataSubjectRequest?.inputData?.title || "");
  }, [dataSubjectRequest?.inputData?.title, setDocumentName]);

  /* PERMISSION */
  const noWritePermission = useMemo<boolean>(() => {
    if (!dataSubjectRequest || !dataSubjectRequest?.inputData || !auth) {
      return false;
    }
    const { orgUnitId, assignedTo } = dataSubjectRequest.inputData;
    if (!orgUnitId) return false;
    if (assignedTo === auth?.uid || auth?.permissions.includes("dsr_write_all")) return false;
    if (isPartOfUserDepartments(...[orgUnitId])) return false;
    return true;
  }, [auth, dataSubjectRequest, isPartOfUserDepartments]);

  /* STEPPER */
  const onStepClick = useCallback(
    ({ path }) => {
      navigate(`/data-subject-requests/${id}/${path}`);
    },
    [id, navigate]
  );

  const tenantSettings = useCurrentTenantSettings();
  const secureDataRoomUrl = useMemo(() => {
    return tenantSettings.find(setting => setting.flag_key === "secure_data_room_url")?.flag_value;
  }, [tenantSettings]);
  const secureDataRoomAddress = useMemo(() => {
    return `${secureDataRoomUrl}/rooms/login#roomID=${dataSubjectRequest?.dataRoomID}&role=agent`;
  }, [secureDataRoomUrl, dataSubjectRequest?.dataRoomID]);

  const secureDataRoomAddressLogin = useMemo(() => {
    return `${secureDataRoomUrl}/login/invite#caralegalDsrId=${dataSubjectRequest?.id}&firstname=${dataSubjectRequest?.inputData?.firstName}&lastname=${dataSubjectRequest?.inputData?.lastName}&email=${dataSubjectRequest?.inputData?.email}&role=agent`;
  }, [secureDataRoomUrl, dataSubjectRequest]);

  const theme = useTheme();
  const warning = theme.palette.warning.main;

  const steps = useMemo(() => {
    const steps: StepperItemProps[] = requestPaths.map(path => ({
      path,
      label: t(`pagination:${path}`),
      selected: path === page
    }));
    steps.push({
      icon: dataSubjectRequest?.dataRoomID ? null : <AddIcon />,
      label: t("pagination:data_room"),
      labelElement: (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: dataSubjectRequest?.unreadMessageCount ? "0px" : "16px",
            marginLeft: "-5px"
          }}
        >
          {dataSubjectRequest?.unreadMessageCount ? (
            <Box
              style={{
                background: warning,
                width: "16px",
                height: "16px",
                borderRadius: 99999,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <span
                style={{
                  fontSize: 11,
                  color: "white"
                }}
              >
                {dataSubjectRequest?.unreadMessageCount}
              </span>
            </Box>
          ) : null}
          <OpenInNew color="action" />
        </Box>
      ),
      path: "data-room",
      href: dataSubjectRequest?.dataRoomID ? secureDataRoomAddress : secureDataRoomAddressLogin,
      tooltip: !dataSubjectRequest?.dataRoomID ? t("pagination:no_data_room") : undefined,
      disabled: false // !dataSubjectRequest?.dataRoomID
    });
    return steps;
  }, [page, t, dataSubjectRequest, secureDataRoomAddress, secureDataRoomAddressLogin, warning]);
  const stepperEl = useMemo(() => <Stepper steps={steps} onClick={onStepClick} />, [onStepClick, steps]);

  /* STATUSES */
  const statuses = useMemo<{ id: REQUEST_STATUSES; label: string; icon: React.ReactNode }[]>(() => {
    return (["OPEN", "INPROGRESS", "REVIEW", "DONE"] as REQUEST_STATUSES[]).map(status => ({
      id: status,
      label: t("filter_criteria:" + (status as REQUEST_STATUSES).toLowerCase()),
      icon: statusIconMap[status as REQUEST_STATUSES]
    }));
  }, [t]);

  return (
    <DocMetaView
      loading={!initialized}
      notFound={initialized && dataSubjectRequest === null}
      collection={COLLECTIONS.DATA_SUBJECT_REQUESTS}
      metaViewContent={
        <MetaView
          docId={id}
          tabs={["assistant", "comments", "todos"]}
          collection={COLLECTIONS.DATA_SUBJECT_REQUESTS}
          translationKey={"data_subject_requests_overview_general_page"}
        />
      }
    >
      <DocView
        pagination={stepperEl}
        header={dataSubjectRequest?.inputData?.title}
        onHeaderChange={onHeaderChange}
        onStatusChange={onStatusChange}
        statusId={dataSubjectRequest?.status}
        statuses={statuses}
        docProperties={[{ label: dataSubjectRequest?.inputData?.id || "", icon: <>{"#"}</> }]}
        disabled={noWritePermission}
      >
        {dataSubjectRequest && page === "general" && (
          <RequestGeneralPage request={dataSubjectRequest} disabled={noWritePermission} />
        )}
        {dataSubjectRequest && page === "data" && <RequestDataPage request={dataSubjectRequest} />}
      </DocView>
    </DocMetaView>
  );
};

export default RequestPage;
