import { apiEndpoints } from "./apiEndpoint";
import { defaultOTCAuthenticatedAxios } from "./axios/loggedInAxiosProvider";

export const axiosInstance = defaultOTCAuthenticatedAxios({
  baseURL: `${apiEndpoints.dataSubjectRequestUrl}/api/v1/datasubjectrequests`
});

export interface DataSubjectRequestDTOs {
  readonly dataSubjectRequests: DataSubjectRequestDTO[];
}

export interface DataSubjectRequestDTO {
  readonly id: string;
  readonly comments: DSRCommentDTO[];
  readonly created: string;
  readonly createdBy: string;
  readonly updatedAt: string;
  readonly deleted: boolean;
  readonly inputData: DSRInputData;
  readonly status: string;
  readonly externalCreator: boolean;
  readonly labelIds: string[];
  readonly openTasks: string;
  readonly dataRoomID?: string;
  readonly unreadMessageCount?: number;
}

export interface DSRCommentSkeleton {
  readonly contentState: string;
  readonly created: string;
  readonly createdBy: string;
  readonly mentionedUsers: string[];
  readonly questionId: string;
  readonly value: string;
}

export interface DSRCommentReplyDTO extends DSRCommentSkeleton {
  readonly commentReplyId: string;
}

export interface DSRCommentDTO extends DSRCommentSkeleton {
  readonly commentId: string;
  readonly replies: DSRCommentReplyDTO[];
}

export interface DSRComment {
  readonly id: string;
  readonly createdAt: Date;
  readonly creatorUID: string;
  readonly mentionedUsers: string[];
  readonly questionId: string;
  readonly editorState: string;
  readonly value: string;
  readonly parentCommentId: string | null;
  readonly dataSubjectRequestId: string;
  readonly replies: DSRComment[];
}

interface DSRInputData {
  readonly id: string;
  readonly assignedTo: string;
  readonly title: string;
  readonly orgUnitId: string;
  readonly furtherOrgUnitIds: string[];
  readonly description: string;
  readonly dueDate: string | undefined;
  readonly isDataSubjectVerified: "yes" | "no" | null;
  readonly personGroups: string[];
  readonly dataTypeIds?: string[];
  readonly receivedOn: string;
  readonly type: string[];
  readonly firstName?: string;
  readonly lastName?: string;
  readonly email?: string;
}

export interface DataSubjectRequestShortDTO {
  readonly id: string;
  readonly title: string;
  readonly description: string;
  readonly subTitle: string;
  readonly orgUnitId: string;
  readonly status: string;
  readonly labelIds: string[];
  readonly type: string[];
  readonly createdAt: Date;
  readonly createdBy: string;
}
export interface DataSubjectFilterParameter {
  readonly dataSubjectRequestIds?: string[];
  readonly typeIds?: string[];
}

export const getDataSubjectRequestsApi = async (
  filter: DataSubjectFilterParameter
): Promise<{
  readonly datasubjectrequests: DataSubjectRequestShortDTO[];
}> => {
  const response = await axiosInstance.get("/", {
    params: { ...(filter || {}) }
  });
  return response.data;
};

export const getDataSubjectRequestApi = async (dataSubjectRequestId: string) => {
  const response = await axiosInstance.get<Partial<DataSubjectRequestDTO>>(`/${dataSubjectRequestId}`);
  return response.data;
};

export const deleteDataSubjectRequestsApi = async (dataSubjectRequestId: string) => {
  await axiosInstance.delete(`/` + dataSubjectRequestId);
};

export interface DSRUpdateRequestDTO {
  readonly title?: string | null;
  readonly orgUnitId?: string;
  readonly assignedTo?: string | null;
  readonly description?: string | null;
  readonly dueDate?: string | null;
  readonly receivedOn?: string;
  readonly type?: string[];
  readonly personGroups?: string[];
  readonly dataTypeIds?: string[];
  readonly isDataSubjectVerified?: "yes" | "no" | null;
  readonly status?: "OPEN" | "DONE" | "REVIEW" | "INPROGRESS";
  readonly labelIds?: string[];
  readonly furtherOrgUnitIds?: string[];
  readonly firstName?: string;
  readonly lastName?: string;
  readonly email?: string;
}

export const updateDataSubjectRequestsApi = async function (
  dataSubjectRequestId: string,
  {
    orgUnitId,
    furtherOrgUnitIds,
    title,
    type,
    assignedTo,
    description,
    dueDate,
    receivedOn,
    personGroups,
    dataTypeIds,
    isDataSubjectVerified,
    status,
    labelIds,
    firstName,
    lastName,
    email
  }: DSRUpdateRequestDTO = {}
) {
  await axiosInstance.patch(`/${dataSubjectRequestId}`, {
    orgUnitId: orgUnitId,
    furtherOrgUnitIds,
    title: title,
    assignedTo: assignedTo,
    description: description,
    dueDate: dueDate,
    receivedOn: receivedOn,
    type: type,
    personGroups: personGroups,
    dataTypeIds: dataTypeIds,
    isDataSubjectVerified: isDataSubjectVerified,
    status: status,
    labelIds: labelIds,
    firstName: firstName,
    lastName: lastName,
    email: email
  });
};

export interface DSRCreateRequestDTO {
  readonly title: string;
  readonly orgUnitId?: string;
  readonly assignedTo?: string;
  readonly description?: string | null;
  readonly dueDate?: string;
  readonly receivedOn?: string | null;
  readonly type?: string[];
  readonly personGroups?: string[];
  readonly dataTypeIds?: string[];
  readonly labelIds?: string[];
  readonly furtherOrgUnitIds?: string[];
  readonly firstName?: string;
  readonly lastName?: string;
  readonly email?: string;
}

export async function createDataSubjectRequestApi(
  title: string,
  optionalPayload: Omit<DSRCreateRequestDTO, "title"> = {}
) {
  const response = await axiosInstance.post("/", {
    ...optionalPayload,
    title
  });
  return response.headers["x-resource-id"] || "";
}

export async function createExternalDataSubjectRequestApi(
  title: string,
  optionalPayload: Omit<DSRCreateRequestDTO, "title"> = {}
) {
  const response = await axiosInstance.post("/external", {
    ...optionalPayload,
    title
  });
  return response.headers["x-resource-id"] || "";
}

export async function createDataSubjectRequestTasksApi(dataSubjectRequestId: string, language: string) {
  await axiosInstance.post(
    `/${dataSubjectRequestId}/tasks`,
    {},
    {
      headers: { "Accept-Language": language }
    }
  );
}

export async function getDataSubjectRequestStatsApi(orgUnitId?: string, labelId?: string) {
  const response = await axiosInstance.get("/stats", { params: { orgUnitId, labelId } });
  return response.data.stats || [];
}
