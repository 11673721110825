import React, { useCallback, useState } from "react";
import { Box, CircularProgress, ClickAwayListener, MenuList, Paper, Popper, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ExportMenuItem } from "./OverviewExportMenu";
import { GetApp } from "@material-ui/icons";
import { useIsFeaturePresent } from "../../../hook/useIsFeaturePresent";
import { FEATURES } from "../../../app/features";
import OverviewIconButton from "./OverviewIconButton";

export interface OverviewExportProps {
  readonly onExport: (val: string) => void;
}

export const OverviewExport = ({ onExport }: OverviewExportProps) => {
  const { t } = useTranslation("overview");
  const [open, setOpen] = React.useState(false);
  const [anchorRef, setAnchorRef] = useState(null);
  const excelExportEnabled = useIsFeaturePresent(FEATURES.PA_EXCEL_EXPORT);
  const attachmentExportEnabled = useIsFeaturePresent(FEATURES.PA_ATTACHMENT_EXPORT);

  /* ACTIONS */
  const handleToggle = useCallback(event => {
    setAnchorRef(event.currentTarget);
    setOpen(prevOpen => !prevOpen);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setAnchorRef(null);
  }, []);

  const exportEl = (
    <>
      <Box py={4} px={2} pb={1}>
        <Typography align="left" style={{ fontWeight: 700 }} variant="subtitle2">
          {t("download")}
        </Typography>
      </Box>
      <Box>
        <MenuList id="split-button-menu" autoFocusItem>
          <ExportMenuItem text={t("export_pdf")} onExport={onExport} format="pdf" />
          {excelExportEnabled && <ExportMenuItem text={t("export_xls")} onExport={onExport} format="xlsx" />}
          {attachmentExportEnabled && (
            <ExportMenuItem text={t("export_attachments")} onExport={onExport} format="attachments" />
          )}
        </MenuList>
      </Box>
    </>
  );

  /* ACTIONS */
  const exportMenuEl = (
    <Popper open={open} anchorEl={anchorRef} placement={"bottom-start"} transition disablePortal>
      {() => (
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <Box>{exportEl}</Box>
          </ClickAwayListener>
        </Paper>
      )}
    </Popper>
  );

  return (
    <Box display="flex">
      <ExportButton handleClick={handleToggle} />
      {exportMenuEl}
    </Box>
  );
};

export const OverviewPdfXlsExport = ({ onExport }: OverviewExportProps) => {
  const { t } = useTranslation("overview");
  const [open, setOpen] = React.useState(false);
  const [anchorRef, setAnchorRef] = useState(null);
  const excelExportEnabled = useIsFeaturePresent(FEATURES.PA_EXCEL_EXPORT);

  /* ACTIONS */
  const handleToggle = useCallback(event => {
    setAnchorRef(event.currentTarget);
    setOpen(prevOpen => !prevOpen);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setAnchorRef(null);
  }, []);

  const exportEl = (
    <>
      <Box py={4} px={2} pb={1}>
        <Typography align="left" style={{ fontWeight: 700 }} variant="h4">
          {t("download")}
        </Typography>
      </Box>
      <Box>
        <MenuList id="split-button-menu" autoFocusItem>
          <ExportMenuItem text={t("export_pdf")} onExport={onExport} format="pdf" />
          {excelExportEnabled && <ExportMenuItem text={t("export_xls")} onExport={onExport} format="xlsx" />}
        </MenuList>
      </Box>
    </>
  );

  /* ACTIONS */
  const exportMenuEl = (
    <Popper open={open} anchorEl={anchorRef} placement={"bottom-start"} transition disablePortal>
      {() => (
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <Box>{exportEl}</Box>
          </ClickAwayListener>
        </Paper>
      )}
    </Popper>
  );

  return (
    <Box display="flex">
      <ExportButton handleClick={handleToggle} />
      {exportMenuEl}
    </Box>
  );
};

export interface ExportButtonProps {
  readonly handleClick: (event?: any) => void | Promise<void>;
}

export const ExportButton = ({ handleClick }: ExportButtonProps) => {
  const { t } = useTranslation("overview");

  const [isExporting, setIsExporting] = useState(false);
  const handleClickWrapper = useCallback(
    async (event?: any) => {
      const response = handleClick(event);
      if (response instanceof Promise) {
        try {
          setIsExporting(true);
          const result = await response;
          setIsExporting(false);
          return result;
        } catch (error) {
          setIsExporting(false);
          throw error;
        }
      } else {
        setIsExporting(false);
        return response;
      }
    },
    [handleClick]
  );

  if (isExporting) {
    return (
      <Box height="32px" width="36px" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={18} />
      </Box>
    );
  }

  return (
    <OverviewIconButton tooltip={t("download")} onClick={handleClickWrapper}>
      <GetApp />
    </OverviewIconButton>
  );
};
