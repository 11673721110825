import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import OrgUnitTree from "app/pages/shared/OrgUnitPickerModal/OrgUnitTree";
import TextBody2 from "components/TextBody2/TextBody2";
import { useUserDepartments } from "app/contexts/department-context";
import {
  expandOrgUnitIds,
  orgUnitAndChildren,
  orgUnitsToIdMap,
  orgUnitsToParentIdMap
} from "app/handlers/orgUnitHandler";
import { Department } from "app/handlers/departmentHandler";
import ConfirmationModal, { ConfirmationModalButtonProps } from "components/ConfirmationModal/ConfirmationModal";
import Box from "@mui/material/Box";

interface OrgUnitPickerModalProps {
  readonly open: boolean;
  readonly selectedDepartmentIds: string[];
  readonly visibleDepartmentIds?: string[];
  readonly visibleDepartmentIdsNonRecursive?: boolean;
  readonly enabledOrgUnitIds?: string[];
  readonly linkingOrgUnitsModalHeading?: string;
  readonly linkingOrgUnitsModalSubHeading?: string;
  readonly onClose: () => void;
  readonly onConfirm: (departmentIds: string[]) => void;
  readonly required: boolean;
}

export default function OrgUnitPickerModal({
  open,
  selectedDepartmentIds,
  visibleDepartmentIds,
  visibleDepartmentIdsNonRecursive,
  enabledOrgUnitIds,
  linkingOrgUnitsModalHeading,
  linkingOrgUnitsModalSubHeading,
  onClose,
  onConfirm,
  required
}: OrgUnitPickerModalProps) {
  const { t } = useTranslation("organisation");

  const { departments } = useUserDepartments();

  const visibleDepartments = useMemo<Department[]>(() => {
    if (!departments) {
      return [];
    }
    if (!visibleDepartmentIds) {
      return departments;
    }
    if (visibleDepartmentIds.includes("*")) {
      return departments;
    }

    if (visibleDepartmentIdsNonRecursive) {
      const departmentsMap = orgUnitsToIdMap(departments);
      return visibleDepartmentIds
        .map(id => departmentsMap.get(id))
        .filter((department): department is Department => !!department);
    }

    return (visibleDepartmentIds || []).reduce<Department[]>((acc, next) => {
      return [...acc, ...orgUnitAndChildren(next, departments)];
    }, []);
  }, [departments, visibleDepartmentIds, visibleDepartmentIdsNonRecursive]);

  const visibleDepartmentsMap = useMemo<Map<string, Department>>(
    () => orgUnitsToIdMap(visibleDepartments),
    [visibleDepartments]
  );

  const parentToChildOfVisibleDepartments = useMemo<Map<string, Department[]>>(
    () => orgUnitsToParentIdMap(visibleDepartments),
    [visibleDepartments]
  );

  const enabledDepartmentIds = useMemo<Set<string>>(() => {
    if (enabledOrgUnitIds?.includes("*") || !enabledOrgUnitIds) {
      return new Set(visibleDepartments.map(department => department.id));
    }

    return expandOrgUnitIds(enabledOrgUnitIds, parentToChildOfVisibleDepartments);
  }, [enabledOrgUnitIds, parentToChildOfVisibleDepartments, visibleDepartments]);

  const filterCheckedByAvailable = useCallback(
    (departmentId: string) => enabledDepartmentIds.has("*") || enabledDepartmentIds.has(departmentId),
    [enabledDepartmentIds]
  );

  const [checkedDepartmentIds, setCheckedDepartmentIds] = useState<Set<string>>(new Set());
  useEffect(() => {
    if (!open) {
      return;
    }

    setCheckedDepartmentIds(
      new Set(
        selectedDepartmentIds.includes("*")
          ? visibleDepartments.map(department => department.id)
          : selectedDepartmentIds
      )
    );
  }, [selectedDepartmentIds, visibleDepartments, open]);

  const [checkedDepartmentIdsChanged, setCheckedDepartmentIdsChanged] = useState(false);
  const onCheckedDepartmentIdsChanged = useCallback((newCheckedDepartmentIds: Set<string>) => {
    setCheckedDepartmentIds(newCheckedDepartmentIds);
    setCheckedDepartmentIdsChanged(true);
  }, []);

  const checkedFilteredDepartmentIds = useMemo<string[]>(
    () => [...checkedDepartmentIds].filter(filterCheckedByAvailable),
    [checkedDepartmentIds, filterCheckedByAvailable]
  );

  const selectedDisabledOrgUnitIds = useMemo<string[]>(() => {
    const expandedOrgUnitIds: string[] = selectedDepartmentIds.includes("*")
      ? departments.map(department => department.id)
      : selectedDepartmentIds;

    return expandedOrgUnitIds.filter(id => !filterCheckedByAvailable(id));
  }, [departments, filterCheckedByAvailable, selectedDepartmentIds]);

  const confirmSelection = useCallback(() => {
    if (!checkedDepartmentIdsChanged) {
      onConfirm(selectedDepartmentIds); // to support wildcard values in original
    } else {
      // if changed, then convert to non wildcard values
      onConfirm([...new Set([...selectedDisabledOrgUnitIds, ...checkedFilteredDepartmentIds])]);
    }

    onClose();
  }, [
    checkedDepartmentIdsChanged,
    onConfirm,
    selectedDisabledOrgUnitIds,
    checkedFilteredDepartmentIds,
    onClose,
    selectedDepartmentIds
  ]);

  const buttons = useMemo(
    () =>
      [
        {
          confirmButton: false,
          title: t("common:cancel"),
          variant: "outlined",
          color: "primary",
          size: "medium",
          onClick: onClose
        },
        {
          confirmButton: true,
          title: t("common:confirm"),
          variant: "contained",
          color: "primary",
          size: "medium",
          disabled: required ? checkedFilteredDepartmentIds.length < 1 : false,
          onClick: confirmSelection
        }
      ] as ConfirmationModalButtonProps[],
    [checkedFilteredDepartmentIds.length, confirmSelection, onClose, t, required]
  );

  const stopEventPropagation = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);

  const modalBody = (
    <Box height={400} overflow={"auto"} onClick={stopEventPropagation}>
      <Box mb={2}>
        <TextBody2 text={linkingOrgUnitsModalSubHeading || t("orgunit_picker:generic_description")} />
      </Box>
      <OrgUnitTree
        allDepartments={visibleDepartmentsMap}
        parentToChildDepartments={parentToChildOfVisibleDepartments}
        enabledDepartmentIds={enabledDepartmentIds}
        checkedDepartmentIds={checkedDepartmentIds}
        onCheckedChanged={onCheckedDepartmentIdsChanged}
      />
    </Box>
  );
  return (
    <ConfirmationModal
      variant={"info"}
      modalBody={modalBody}
      modalOpen={open}
      modalTitle={linkingOrgUnitsModalHeading || t("orgunit_picker:generic_title")}
      onClose={onClose}
      buttons={buttons}
      modalText={""}
    />
  );
}
