import React, { useCallback, useEffect, useMemo, useState } from "react";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweepOutlined";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import DashboardIcon from "@mui/icons-material/SpaceDashboardOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import { useDocumentName } from "app/contexts/document-name-context";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import SecurityIcon from "@mui/icons-material/SecurityOutlined";
import HomeWorkIcon from "@mui/icons-material/HomeWorkOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import CastForEducationIcon from "@mui/icons-material/CastForEducationOutlined";
import { HasFeatureToggleOn, HasPageAccess, HasUserPermission, ShowPageIf } from "app/router/router-filters";
import { useAuthentication } from "app/handlers/authentication/authentication-context";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import { FEATURES, USER_FEATURE_IDS } from "app/features";
import { useNavigate } from "react-router-dom";
import { usePathName } from "app/router/router-custom-hooks";
import { AppsOutageOutlined, ReportGmailerrorredOutlined, TableRowsOutlined } from "@mui/icons-material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import MemoryOutlinedIcon from "@mui/icons-material/MemoryOutlined";
import KeyIcon from "@mui/icons-material/Key";
import TaskIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import {
  Badge,
  Box,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme
} from "@mui/material";
import BuildCircleOutlinedIcon from "@mui/icons-material/BuildCircleOutlined";
import colors from "theme/palette/colors";
import LogoSidebar from "./LogoSidebar";
import LogoSidebarCustom from "./LogoSidebarCustom";

import { COLLECTIONS } from "app/collections";
import { useAvailableLanguages } from "hook/useAvailableLanguages";
import { useDataTypeTree } from "app/api/dataAssetApi";
import { useSidebarUnseen } from "./useSidebarUnseen";
import { useUserAndTenantData } from "app/handlers/userAndTenant/user-tenant-context";

import { sidebarZIndex } from "./sidebarZIndex";
import { getFileAsBlob } from "app/api/file-storage/attachmentsApi";
import { useTranslation } from "react-i18next";
import { paReadPermissions } from "../../../handlers/permissionHandler";
import { useIsFeaturePresent } from "hook/useIsFeaturePresent";

import Topbar from "../Topbar/Topbar";
import { useLawPilotTrainingUrl } from "hook/useTenantSettings";
import { SxProps } from "@mui/system/styleFunctionSx";
import { useProcessVariations } from "./useProcessVariations";

const drawerWidthClosed = 70;
const drawerWidth = 300;
const BoxShadow = "0px 0px 40px 0px rgb(204 204 204 / 50%)";
const BorderRadius = "5px";
const sidebarBadgeMax = 9;
const paperProps = { elevation: 2 };

const sx = {
  navIcon: { height: "20px", width: "20px" }
};

export default function SidebarWrapper() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const transition = useMemo(
    () =>
      theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen
      }),
    [open, theme.transitions]
  );

  return (
    <>
      <Topbar />
      <Drawer
        open={true}
        variant="permanent"
        sx={{
          border: 0,
          borderBottomLeftRadius: 0,
          borderRadius: BorderRadius,
          borderTopLeftRadius: 0,
          boxShadow: BoxShadow,
          flexShrink: 0,
          height: "calc(100% - 48px)",
          overflow: "unset",
          position: "fixed",
          top: "24px",
          whiteSpace: "nowrap",
          zIndex: sidebarZIndex,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            maxWidth: drawerWidth,
            overflowX: "hidden",
            overflowY: open ? "auto" : "hidden",
            transition: transition,
            width: open ? drawerWidth : drawerWidthClosed
          }
        }}
        PaperProps={paperProps}
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
      >
        <Sidebar sidebarOpen={open} onSidebarClose={handleDrawerClose} />
      </Drawer>
    </>
  );
}

const Sidebar = React.memo(function ({
  sidebarOpen,
  onSidebarClose
}: {
  readonly sidebarOpen: boolean;
  readonly onSidebarClose: () => void;
}) {
  const { auth, user } = useAuthentication();
  const theme = useTheme();
  const { tenantData, isExternalUser } = useUserAndTenantData();
  const { t, i18n } = useTranslation("sidebar");
  const { availableLanguages, isLoadedFromTenantData: availableLanguagesLoadedFromTenant } = useAvailableLanguages();
  const [lng, setLng] = useState("");

  const { sidebarNewItems } = useSidebarUnseen();

  const isHomePageEnabled = useIsFeaturePresent(FEATURES.USER_HOME_PAGE);
  const isTokenEnabled = useIsFeaturePresent(FEATURES.TOKEN);

  useEffect(() => {
    if (!tenantData?.features || !availableLanguagesLoadedFromTenant) return;

    const currentLanguage = i18n.language;
    if (!availableLanguages.includes(currentLanguage)) {
      const fallbackLanguage = tenantData.companyLanguage || "en";
      setLng(fallbackLanguage);
      i18n.changeLanguage(fallbackLanguage);
      return;
    }

    setLng(currentLanguage);
  }, [availableLanguagesLoadedFromTenant, tenantData?.features, availableLanguages, i18n, tenantData?.companyLanguage]);

  const onSubSideBarClicked = useCallback(() => {
    onSidebarClose?.();
  }, [onSidebarClose]);

  const allowDataSubjectRequestsSubmission = useCallback(
    pageAccess => {
      return !pageAccess.includes("data_subject_requests") && !isExternalUser(user?.id || "");
    },
    [isExternalUser, user?.id]
  );

  const { unseenCount } = useDataTypeTree();

  const tenantId = useMemo(() => auth?.tenantId || "", [auth]);
  const tenantName = useMemo(() => tenantData?.name || "", [tenantData]);

  const [hasCustomLogo, setHasCustomLogo] = useState(false);
  const [customLogoUrl, setCustomLogoUrl] = useState<string | null>(null);

  const lawPilotTrainingUrl = useLawPilotTrainingUrl(tenantId);
  const onShowTrainingClick = useCallback(() => {
    if (lawPilotTrainingUrl.startsWith("http://")) {
      window.open("https://" + lawPilotTrainingUrl.slice(7));
    }
    if (!lawPilotTrainingUrl.startsWith("https://")) {
      window.open("https://" + lawPilotTrainingUrl);
    } else {
      window.open(lawPilotTrainingUrl);
    }
  }, [lawPilotTrainingUrl]);

  useEffect(() => {
    if (!tenantId) return;
    getFileAsBlob({ folderName: "brand", fileName: "logo.png" }).then(logo => {
      if (logo) {
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(logo);
        setCustomLogoUrl(imageUrl);
        setHasCustomLogo(true);
      } else {
        setHasCustomLogo(false);
      }
    });
  }, [tenantId]);

  const userHasProcessorPermissionButNoPA =
    auth?.permissions.includes("processor_pa_read_org") && !auth?.permissions.some(p => paReadPermissions.includes(p));

  const paVariations = useProcessVariations();

  return (
    <List>
      {hasCustomLogo ? (
        <LogoSidebarCustom tenantName={tenantName} customLogoUrl={customLogoUrl} open={sidebarOpen} />
      ) : (
        <LogoSidebar open={sidebarOpen} />
      )}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="overline"
          style={{
            color: theme.palette.grey[500],
            paddingLeft: sidebarOpen ? "12px" : 0,
            width: sidebarOpen ? "auto" : 0,
            visibility: sidebarOpen ? "visible" : "hidden"
          }}
        >
          {t("myWork")}
        </Typography>
        <Divider
          variant="middle"
          sx={{
            flex: 1,
            marginLeft: sidebarOpen ? "8px" : "16px",
            borderBottomColor: `${colors.divider}`,
            backgroundColor: `${colors.divider}`
          }}
        />
      </Box>
      {isHomePageEnabled && (
        <NavigateItem targetPath="/home" label={t("home")}>
          <HomeIcon sx={sx.navIcon} />
        </NavigateItem>
      )}

      <HasPageAccess requiredPageAccess={["dashboard"]} returnNothing={true}>
        <NavigateItem targetPath={"/dashboard"} label={t("dashboard")}>
          <DashboardIcon sx={sx.navIcon} />
        </NavigateItem>
      </HasPageAccess>
      <HasPageAccess requiredPageAccess={["tasks"]} returnNothing={true}>
        <NavigateItem
          targetPath={"/tasks/my"}
          label={t("tasks")}
          badgeContent={sidebarNewItems[COLLECTIONS.TASKS]}
          badgeContentMax={sidebarBadgeMax}
        >
          <TaskIcon sx={sx.navIcon} />
        </NavigateItem>
      </HasPageAccess>
      <SidebarDivider sidebarOpen={sidebarOpen} label={t("privacyManagement")} />
      <HasPageAccess requiredPageAccess={["processes"]} returnNothing={true}>
        <NavigateItem
          targetPath={userHasProcessorPermissionButNoPA ? "/processor-pas" : "/processes"}
          label={t("process_overview")}
          isOnPageFn={paVariations.isOnProcessPage}
        >
          <FactCheckOutlinedIcon sx={sx.navIcon} />
        </NavigateItem>
      </HasPageAccess>
      {/** This one is the real business process */}
      <HasFeatureToggleOn feature={FEATURES.BUSINESS_PROCESS} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["business_process"]} returnNothing={true}>
          <NavigateItem targetPath={"/business-process"} label={t("business_process")}>
            <FactCheckOutlinedIcon sx={sx.navIcon} />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasPageAccess requiredPageAccess={["impact_assessment"]} returnNothing={true}>
        <NavigateItem targetPath={"/dpias"} label={t("risk_assessment")} isOnPageFn={paVariations.isOnDPIAPage}>
          <PolicyOutlinedIcon sx={sx.navIcon} />
        </NavigateItem>
      </HasPageAccess>
      <HasFeatureToggleOn feature={FEATURES.DELETION_CONCEPT} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["deletion_concept"]} returnNothing={true}>
          <NavigateItem targetPath={"/deletion-concept"} label={t("deletion_concept")}>
            <DeleteSweepIcon sx={sx.navIcon} />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasPageAccess requiredPageAccess={["service_providers"]} returnNothing={true}>
        <NavigateItem
          targetPath={"/external-recipients"}
          label={t("service_providers")}
          badgeContent={sidebarNewItems[COLLECTIONS.EXTERNAL_RECIPIENTS]}
          badgeContentMax={sidebarBadgeMax}
        >
          <HomeWorkIcon sx={sx.navIcon} />
        </NavigateItem>
      </HasPageAccess>
      <HasFeatureToggleOn feature={FEATURES.DATA_BREACHES} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["data_breaches"]} returnNothing={true}>
          <NavigateItem targetPath={"/data-breaches"} label={t("data_breaches")}>
            <ReportGmailerrorredOutlined sx={sx.navIcon} />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasFeatureToggleOn feature={FEATURES.DATA_SUBJECT_REQUEST} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["data_subject_requests"]} returnNothing={true}>
          <NavigateItem targetPath={"/data-subject-requests"} label={t("requests")}>
            <QuizOutlinedIcon sx={sx.navIcon} />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasFeatureToggleOn feature={FEATURES.DSR_BASIC_SUBMISSION} navigateAway={false}>
        <ShowPageIf pageAccessCondition={allowDataSubjectRequestsSubmission}>
          <NavigateItem targetPath={"/data-subject-requests-submission"} label={t("requests_submission")}>
            <QuizOutlinedIcon sx={sx.navIcon} />
          </NavigateItem>
        </ShowPageIf>
      </HasFeatureToggleOn>
      <SidebarDivider sidebarOpen={sidebarOpen} label={t("governanceAndOperations")} />
      {/** This one is the old one based on pa */}
      <HasFeatureToggleOn feature={FEATURES.BUSINESS_PROCESS_LEGACY} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["processes"]} returnNothing={true}>
          <NavigateItem targetPath={"/bp"} label={t("process_overview_business")} isOnPageFn={paVariations.inOnBPPage}>
            <CorporateFareOutlinedIcon sx={sx.navIcon} />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasFeatureToggleOn feature={FEATURES.AI_TECH_DOCS} navigateAway={false}>
        <HasUserPermission requiredPermission={"aitechdoc_read_org"} returnNothing={true}>
          <NavigateItem targetPath={"/aiacts"} label={t("aiact")} isOnPageFn={paVariations.isOnAIActPage}>
            <MemoryOutlinedIcon sx={sx.navIcon} />
          </NavigateItem>
        </HasUserPermission>
      </HasFeatureToggleOn>
      <HasPageAccess requiredPageAccess={["resources"]} returnNothing={true}>
        <NavigateItem
          targetPath={"/resources"}
          label={t("resources")}
          badgeContentMax={sidebarBadgeMax}
          badgeContent={
            [COLLECTIONS.RESOURCES, COLLECTIONS.DATA_LOCATIONS].reduce(
              (count, sidebarItemName) => count + sidebarNewItems[sidebarItemName] || 0,
              0
            ) + unseenCount
          }
        >
          <TableRowsOutlined sx={sx.navIcon} />
        </NavigateItem>
      </HasPageAccess>
      <HasFeatureToggleOn feature={FEATURES.MEASURES_FEATURE} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["tom"]} returnNothing={true}>
          <NavigateItem targetPath={"/toms/general"} label={t("data_security")}>
            <SecurityIcon sx={sx.navIcon} />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>

      <HasFeatureToggleOn feature={FEATURES.AUDIT} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["audits"]} returnNothing={true}>
          <NavigateItem targetPath={"/audits/instances"} label={t("audits")}>
            <AssignmentTurnedInIcon sx={sx.navIcon} />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>

      <HasFeatureToggleOn feature={FEATURES.ASSETS} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["assets"]} returnNothing={true}>
          <NavigateItem
            targetPath={"/asset-management"}
            label={t("assets_overview:header")}
            badgeContent={sidebarNewItems[COLLECTIONS.ASSETS]}
            badgeContentMax={sidebarBadgeMax}
          >
            <HubOutlinedIcon sx={sx.navIcon} />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>
      <HasFeatureToggleOn feature={FEATURES.RISKS} navigateAway={false}>
        <HasPageAccess requiredPageAccess={["risks"]} returnNothing={true}>
          <NavigateItem targetPath={"/risks/general"} label={t("risks")}>
            <AppsOutageOutlined sx={sx.navIcon} />
          </NavigateItem>
        </HasPageAccess>
      </HasFeatureToggleOn>

      <HasFeatureToggleOn feature={FEATURES.AI_TECH_DOCS} navigateAway={false}>
        <HasUserPermission
          requiredPermission={"aitechdoc_read_org"}
          returnNothing={true}
          alwaysShow={!!sidebarNewItems[COLLECTIONS.AI_TECH_DOCS]?.totalCount}
        >
          <NavigateItem
            targetPath={"/ai-tech-docs"}
            label={t("ai-tech-docs")}
            badgeContent={sidebarNewItems[COLLECTIONS.AI_TECH_DOCS]?.unseenCount}
            badgeContentMax={sidebarBadgeMax}
          >
            <BuildCircleOutlinedIcon sx={sx.navIcon} />
          </NavigateItem>
        </HasUserPermission>
      </HasFeatureToggleOn>
      <HasFeatureToggleOn feature={USER_FEATURE_IDS.WEBSITES} navigateAway={false}>
        <NavigateItem targetPath={"/websites"} label={t("websites")}>
          <GppGoodOutlinedIcon sx={sx.navIcon} />
        </NavigateItem>
      </HasFeatureToggleOn>
      {lawPilotTrainingUrl.includes(".") && (
        <HasFeatureToggleOn feature={FEATURES.LAWPILOT_TRAINING} navigateAway={false}>
          <HasPageAccess requiredPageAccess={["training"]} returnNothing={true}>
            <NavigateItem targetPath={onShowTrainingClick} label={t("training")}>
              <CastForEducationIcon sx={sx.navIcon} />
            </NavigateItem>
          </HasPageAccess>
        </HasFeatureToggleOn>
      )}
      <HasPageAccess requiredPageAccess={["document_center"]} returnNothing={true}>
        <NavigateItem targetPath={"/document-center"} label={t("documents")}>
          <FolderOpenOutlinedIcon sx={sx.navIcon} />
        </NavigateItem>
      </HasPageAccess>
      <HasPageAccess requiredPageAccess={["administration"]} returnNothing={true}>
        <SidebarDivider sidebarOpen={sidebarOpen} label={t("organization")} />
        <HasPageAccess requiredPageAccess={["org_management"]} returnNothing={true}>
          <NavigateItem targetPath={"/org_management"} label={t("organization_management")}>
            <CorporateFareIcon sx={sx.navIcon} />
          </NavigateItem>
        </HasPageAccess>
        <HasPageAccess requiredPageAccess={["user_management"]} returnNothing={true}>
          <NavigateItem targetPath={"/users"} label={t("users")}>
            <PeopleOutlineIcon sx={sx.navIcon} />
          </NavigateItem>
        </HasPageAccess>
        {isTokenEnabled && (
          <HasPageAccess requiredPageAccess={["tokens"]} returnNothing={true}>
            <NavigateItem targetPath={"/tokens"} label={t("tokens")}>
              <KeyIcon sx={sx.navIcon} />
            </NavigateItem>
          </HasPageAccess>
        )}
      </HasPageAccess>
    </List>
  );
});
Sidebar.displayName = "Sidebar";

const SidebarDivider = ({ sidebarOpen, label }: { readonly sidebarOpen: boolean; readonly label: string }) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography
        variant="overline"
        visibility={sidebarOpen ? "visible" : "hidden"}
        color="grey.500"
        paddingLeft={sidebarOpen ? "12px" : 0}
        width={sidebarOpen ? "auto" : 0}
      >
        {label}
      </Typography>
      <Divider
        variant="middle"
        sx={{
          flex: 1,
          marginLeft: sidebarOpen ? "8px" : "16px",
          borderBottomColor: `${colors.divider}`,
          backgroundColor: `${colors.divider}`
        }}
      />
    </Box>
  );
};

const NavigateItem = ({
  targetPath,
  label,
  badgeContent,
  children,
  badgeContentMax,
  isOnPageFn
}: {
  readonly targetPath: string | (() => void);
  readonly label: string;
  readonly badgeContent?: number;
  readonly children: React.ReactNode;
  readonly badgeContentMax?: number;
  readonly isOnPageFn?: () => Promise<boolean>;
}) => {
  const theme = useTheme();
  const { clearDocumentName } = useDocumentName();
  const path = usePathName();
  const navigate = useNavigate();
  const onListItemClick = useCallback(() => {
    if (typeof targetPath === "function") {
      targetPath();
      return;
    }
    clearDocumentName();
    navigate(targetPath);
  }, [navigate, targetPath, clearDocumentName]);

  const [onPageFnValue, setOnPageFnValue] = useState<boolean | null>(null);
  useEffect(() => {
    if (isOnPageFn) {
      isOnPageFn().then(setOnPageFnValue);
    } else {
      setOnPageFnValue(null);
    }
  }, [isOnPageFn]);

  const isOnPage = useMemo(() => {
    if (onPageFnValue !== null) {
      return onPageFnValue;
    }

    if (typeof targetPath === "function") {
      return false;
    }

    const getFirstPath = (input: string) => input.split("/").filter(it => it)[0];
    return getFirstPath(path) === getFirstPath(targetPath);
  }, [onPageFnValue, path, targetPath]);

  const listItemSx = useMemo<SxProps>(
    () => ({
      backgroundColor: isOnPage ? "#3F5DD714" : "transparent",
      paddingLeft: "8px",
      paddingRight: "8px",
      borderRadius: "5px",
      marginLeft: "8px",
      marginRight: "8px",
      "&:hover": {
        backgroundColor: isOnPage ? "#3F5DD714" : "#3F5DD70A"
      }
    }),
    [isOnPage]
  );

  const iconSx = useMemo<SxProps>(
    () => ({
      color: isOnPage ? theme.palette.primary.main : theme.palette.grey[500],
      marginLeft: "6px",
      minWidth: "48px",
      paddingLeft: "2px",
      "& .MuiBadge-colorError": {
        color: theme.palette.common.white,
        border: "2px solid",
        fontSize: "11px",

        paddingTop: "4px",
        paddingBottom: "4px",
        textAlign: "center",
        height: "auto",
        borderRadius: "16px"
      }
    }),
    [isOnPage, theme.palette.grey, theme.palette.primary.main, theme.palette.common.white]
  );

  return (
    <ListItemButton onClick={onListItemClick} sx={listItemSx}>
      <ListItemIcon sx={iconSx}>
        <Badge badgeContent={badgeContent} max={badgeContentMax} color="error" overlap="rectangular">
          {children}
        </Badge>
      </ListItemIcon>
      <ListItemText primary={label} primaryTypographyProps={capitalize} />
    </ListItemButton>
  );
};

const capitalize: { sx: SxProps } = {
  sx: {
    textTransform: "capitalize" // Apply capitalize directly to the Typography
  }
};

export interface Breadcrumb {
  label: string;
  targetPath?: string;
  icon?: React.JSX.Element; // Optional icon
  children?: {
    [key: string]: Breadcrumb | undefined;
    default?: Breadcrumb;
  }; // Support for nested routes
}

// Static path mapping to handle force reload
const sxSize = { height: "20px", width: "20px" };
export const pathMapping: Record<string, Breadcrumb> = {
  home: {
    label: "Home",
    icon: <HomeIcon sx={sxSize} />
  },
  dashboard: {
    label: "dashboard",
    icon: <DashboardIcon sx={sxSize} />
  },
  tasks: {
    label: "tasks",
    icon: <TaskIcon sx={sxSize} />
  },
  bp: {
    label: "process_overview_business",
    icon: <CorporateFareOutlinedIcon sx={sxSize} />
  },
  processes: {
    label: "process_overview",
    icon: <FactCheckOutlinedIcon sx={sxSize} />
  },
  "processor-pas": {
    label: "process_overview",
    icon: <FactCheckOutlinedIcon sx={sxSize} />
  },
  "business-process": {
    label: "business_process",
    icon: <FactCheckOutlinedIcon sx={sxSize} />
  },
  dpias: {
    label: "risk_assessment",
    icon: <PolicyOutlinedIcon sx={sxSize} />
  },
  aiacts: {
    label: "aiact",
    icon: <MemoryOutlinedIcon sx={sxSize} />
  },
  "deletion-concept": {
    label: "deletion_concept",
    icon: <DeleteSweepIcon sx={sxSize} />
  },
  notifications: {
    label: "notifications",
    icon: <NotificationsIcon sx={sxSize} />
  },
  "external-recipients": {
    label: "service_providers",
    icon: <HomeWorkIcon sx={sxSize} />
  },
  "data-breaches": {
    label: "data_breaches",
    icon: <ReportGmailerrorredOutlined sx={sxSize} />
  },
  "data-subject-requests": {
    label: "requests",
    icon: <QuizOutlinedIcon sx={sxSize} />
  },
  "data-subject-requests-submission": {
    label: "requests_submission",
    icon: <QuizOutlinedIcon sx={sxSize} />
  },
  resources: {
    label: "resources",
    icon: <TableRowsOutlined sx={sxSize} />,
    children: {
      "ai-role": {
        label: "resources_ai-role_overview:header"
      },
      "ai-training-method": {
        label: "resources_ai-training-method_overview:header"
      },
      "processing-category": {
        label: "resources_processing-category_overview:header"
      },
      "external-recipient-contract-type": {
        label: "resources_external-recipient-contract-type_overview:header"
      },
      "asset-type": {
        label: "resources_asset-type_overview:header"
      },
      "databreach-cause-type": {
        label: "resources_databreach-cause-type_overview:header"
      },
      "databreach-detection": {
        label: "resources_databreach-detection_overview:header"
      },
      "databreach-impact-type": {
        label: "resources_databreach-impact-type_overview:header"
      },
      "databreach-measure-type": {
        label: "resources_databreach-measure-type_overview:header"
      },
      "data-classification": {
        label: "resources_data-classification_overview:header"
      },
      "data-deletion": {
        label: "resources_data-deletion_overview:header"
      },
      "data-locations": {
        label: "service_providers_overview:data_tab_title"
      },
      derogation: {
        label: "resources_derogation_overview:header"
      },
      "doc-type": {
        label: "resources_doc-type_overview:header"
      },
      encryption: {
        label: "resources_encryption_overview:header"
      },
      "internal-recipient": {
        label: "resources_internal-recipient_overview:header"
      },
      label: {
        label: "resources_label_overview:header"
      },
      "legal-basis": {
        label: "resources_legal-basis_overview:header"
      },
      "data-assets": {
        label: "resources_overview:personGroups"
      },
      "protection-objective": {
        label: "resources_protection-objective_overview:header"
      },
      pseudonymization: {
        label: "resources_pseudonymization_overview:header"
      },
      "dsr-request-type": {
        label: "resources_dsr-request-type_overview:header"
      },
      "legal-retention-period": {
        label: "resources_legal-retention-period_overview:header"
      },
      safeguard: {
        label: "resources_safeguard_overview:header"
      },
      "deletion-trigger": {
        label: "resources_deletion-trigger_overview:header"
      }
    }
  },
  toms: {
    label: "data_security",
    targetPath: "/toms/general",
    icon: <SecurityIcon sx={sxSize} />
  },
  audits: {
    label: "audits",
    targetPath: "/audits/instances",
    icon: <AssignmentTurnedInIcon sx={sxSize} />
  },
  answersets: {
    label: "audits",
    targetPath: "/audits/answersets",
    icon: <AssignmentTurnedInIcon sx={sxSize} />
  },
  templates: {
    label: "audits",
    targetPath: "/audits/templates",
    icon: <AssignmentTurnedInIcon sx={sxSize} />
  },
  "asset-management": {
    label: "assets_overview:header",
    icon: <HubOutlinedIcon sx={sxSize} />
  },
  risks: {
    label: "risks",
    targetPath: "/risks/general",
    icon: <AppsOutageOutlined sx={sxSize} />
  },
  "ai-tech-docs": {
    label: "ai-tech-docs",
    icon: <BuildCircleOutlinedIcon sx={sxSize} />
  },
  websites: {
    label: "websites",
    icon: <GppGoodOutlinedIcon sx={sxSize} />
  },
  "document-center": {
    label: "documents",
    icon: <FolderOpenOutlinedIcon sx={sxSize} />
  },
  org_management: {
    label: "organization_management",
    icon: <CorporateFareIcon sx={sxSize} />
  },
  users: {
    label: "users",
    icon: <PeopleOutlineIcon sx={sxSize} />,
    children: {
      new: {
        label: "manage-user-page:add_user"
      },
      default: {
        label: "manage-user-page:edit_user_information"
      }
    }
  },
  roles: {
    label: "roles",
    icon: <PeopleOutlineIcon sx={sxSize} />,
    children: {
      new: {
        label: "manage-user-page:add_role"
      },
      default: {
        label: "manage-user-page:edit_role_information"
      }
    }
  },
  groups: {
    label: "groups",
    icon: <PeopleOutlineIcon sx={sxSize} />,
    children: {
      new: {
        label: "manage-user-page:add_group"
      },
      default: {
        label: "manage-user-page:edit_group_information"
      }
    }
  },
  tokens: {
    label: "tokens",
    icon: <KeyIcon sx={sxSize} />
  },
  "account-settings": {
    label: "account_settings",
    icon: <AccountCircleOutlinedIcon sx={sxSize} />
  }
};
